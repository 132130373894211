import { useMemo, useState } from "react";
import NavbarCM from "../others/NavbarCM";
import Navbar from "../others/Navbar";
import Footer from "../others/Footer";
import { TipoPortal } from "../../../services/variables";
import "./styles.css";
import api from "../../../services/api";

export default function RedesSociais() {
  const [data, setData] = useState([]);

  async function getData() {
    try {
      const response = await api.get("/footer");
      setData(response.data.res);
    } catch (error) {
      console.log(error);
      return setData([]);
    }
  }

  useMemo(() => {
    getData();
  }, []);

  return (
    <main>
      {TipoPortal === 4 ? <NavbarCM /> : <Navbar />}
      <br />
      <a
        href="https://www.planalto.gov.br/ccivil_03/leis/l9504.htm"
        target="_blank"
        rel="noreferrer"
      >
        <picture>
          <source
            media="(min-width: 600px)"
            srcset="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/483bcd1babe83af2d8e22d0a0b2acc87b495d941_c327e5a1-9312-4020-980e-0ba87491cad4.png"
          />
          <img
            src="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/483bcd1babe83af2d8e22d0a0b2acc87b495d941_c327e5a1-9312-4020-980e-0ba87491cad4.png"
            alt="infor"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "15px",
              objectFit: "cover",
            }}
          />
        </picture>
      </a>
      <br />
      <Footer />
    </main>
  );
}

// <div className="container">
//   <h2 className="title-page">Nossas Redes Sociais Conectadas</h2>
//   <p className="subtitulo">
//     Explore além dos corredores municipais! descubra nossas redes sociais
//     e mantenha-se atualizado com as últimas notícias, eventos e decisões
//     importantes. Junte-se a nós online e faça parte do diálogo cívico. Sua
//     participação faz a diferença!
//   </p>
//   <br />
//   <br />
//   {data.length === 0 && <p>Nenhuma rede cadastrada.</p>}
//   {data.length > 0 &&
//     data.map((item) => (
//       <div className="social-container" key={item.ID}>
//         {item.LINK_INSTAGRAM && (
//           <a className="link" target="_blank" href={item.LINK_INSTAGRAM}>
//             <img
//               src="/icons/instagram.svg"
//               alt="logo Instagram"
//               className="svg-social"
//             />
//             <p className="text-social">Instagram</p>
//           </a>
//         )}
//         {item.LINK_FACEBOOK && (
//           <a className="link" target="_blank" href={item.LINK_FACEBOOK}>
//             <img
//               src="/icons/facebook.svg"
//               alt="logo Facebook"
//               className="svg-social"
//             />
//             <p className="text-social">Facebook</p>
//           </a>
//         )}
//         {item.LINK_WHATSAPP && (
//           <a className="link" target="_blank" href={item.LINK_WHATSAPP}>
//             <img
//               src="/icons/whatsapp.svg"
//               alt="logo whatsapp"
//               className="svg-social"
//             />
//             <p className="text-social">WhatsApp</p>
//           </a>
//         )}
//         {/* <a
//           className="link"
//           target="_blank"
//           href="#"
//         >
//           <img
//             src="/icons/youtube.svg"
//             alt="logo youtube"
//             className="svg-social"
//           />
//           <p className="text-social">YouTube</p>
//         </a> */}
//       </div>
//     ))}
// </div>
