const idPortalApi = "3684ef3ebb550579561c57466d8c73a7b8025fd1";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmsantaluzia.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsantaluzia.ma.gov.br",
  NomePortal: "Santa Luzia",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/AM57pTe2hdCJYZyT7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31842.101751448452!2d-45.67802619323731!3d-3.9662199895721164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92ca539a006f8ea9%3A0xc2895c43d1977844!2sSanta%20Luzia%2C%20MA%2C%2065390-000!5e0!3m2!1spt-BR!2sbr!4v1722187384420!5m2!1spt-BR!2sbr",
};
