import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {NomeTipoPortal,NomePortal,linkArquivos} from '../../../../../../services/variables'
import {logoPortalBase64} from '../../../../../../services/logoBase64'

export default  function PDFgenerateSubTypes(files,infor) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
    const data = files
    

    const arrayData2 = [[
        {text: 'DATA',bold: true,fontSize: 11},
        {text: 'TITULO', bold: true, fontSize: 11}, 
        {text: 'TIPO',bold: true,fontSize: 11},
        {text: 'SUBTIPO',bold: true,fontSize: 11},
    ]]
    const arrayInfor = []



    data.map((d, i) => {
        arrayData2.push([
            {text: d.DATA,fontSize: 10},
            {text: d.TITULO, fontSize: 10}, 
            {text: d.TIPO, fontSize: 10},
            {text: d.SUBTIPO, fontSize: 10},
        ])
    }) 
    

    const details = [
        {image: logoPortalBase64,width: 50,height: 50,alignment: 'center'},	
        {text: `Documentos\n\n`,fontSize: 14,alignment: 'center',},

        {
			style: 'tableExample',
			table: {
				headerRows: 1,
				body: arrayData2
			}
		},
    ]
    const footer = [{text: ``}]
    
    const docDefinitions = {
        pageSize: 'A4',
        pageMargins:[15,50,15,40],
        content: [details],
        footer: [footer],
        
    }
 pdfMake.createPdf(docDefinitions).open()//.print()//.download()
    
}